/**
 * @namespace components
 */

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './TruncateText.scss';

/**
 * @class TruncateText
 *
 * @description Shortens text to a maximum of 10 words / 50 characters.
 *
 * @memberof components
 *
 *
 * @property {boolean=} disableHover - Boolean that disables the hover state
 * @property {string=} text - Display text that can be truncated
 *
 */

class TruncateText extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rules: {
                length: 50,
                words: 10
            },
            truncated: null,
            initialText: ''
        };

        this.truncateIfLong = this.truncateIfLong.bind(this);
    }

    componentDidMount() {
        this.truncateIfLong();
    }

    truncateIfLong() {
        let initialText = this.props.text || '';
        let truncated = null;
        const length = this.state.rules.length;
        const words = this.state.rules.words;

        if (initialText.length > length) {
            /* explode to array */
            let arrayOfWords = initialText
                .substring(0, length)
                .split(' ')
                .splice(0, words);

            /* join it up */
            truncated = `${arrayOfWords.join(' ')}...`;
        }

        this.setState({
            initialText,
            truncated
        });
    }

    render() {
        const initialText = this.state.initialText;
        const truncated = this.state.truncated;

        const disableHover = this.props.disableHover;

        const wrapperNames = classNames({
            'truncated-wrapper': true,
            'was-truncated': truncated && !disableHover
        });

        const truncatedClassNames = classNames({
            truncated: truncated && !disableHover,
            'initial-text': !truncated || disableHover
        });

        return (
            <span className={wrapperNames}>
                <span className={truncatedClassNames}>{truncated ? truncated : initialText}</span>
                {truncated && !disableHover && <span className="show-initial">{initialText}</span>}
            </span>
        );
    }
}

TruncateText.propTypes = {
    disableHover: PropTypes.bool,
    text: PropTypes.string
};

export default TruncateText;
